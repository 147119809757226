import * as React from 'react';
import {useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Settings from '@mui/icons-material/Settings';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import Button from '@mui/material/Button';
import {LockReset, Logout, ManageAccounts, Person} from "@mui/icons-material";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import WebhookIcon from '@mui/icons-material/Webhook';
import ApiIcon from '@mui/icons-material/Api';
import {UserAccountContext} from '../../providers/UserAccountProvider';
import {useTranslation} from 'react-i18next';
import AppBarButton from './AppBarButton';
import {Typography} from '@mui/material';
import {colors} from '../../themes/AppTheme';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import PropertiesLoader from '../../properties/PropertiesLoader';
import {AuthContext, IAuthContext} from 'react-oauth2-code-pkce';
import {useIntercom} from 'react-use-intercom';
import useAccountApi, {FeaturePackage} from '../../hooks/account/Account';
import {ImpersonateUserContext} from "../../providers/ImpersonateUserProvider";
import ReactGA from "react-ga4";

const properties = PropertiesLoader();

export default function AccountMenu() {
    const {t} = useTranslation();
    const {user} = useContext(UserAccountContext)
    const {impersonateId, setImpersonateId, setImpersonateUsername} = useContext(ImpersonateUserContext)
    const {account} = useAccountApi()
    const auth: IAuthContext = useContext(AuthContext)
    const {shutdown} = useIntercom();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function getAccountPackageLabel(featurePackage: FeaturePackage | undefined) {
        switch (featurePackage) {
            case FeaturePackage.LITE:
                return t('account_dropdown.package_label.lite_package');
            case FeaturePackage.CORE:
                return t('account_dropdown.package_label.core_package');
            case FeaturePackage.PLUS:
                return t('account_dropdown.package_label.plus_package');
            case FeaturePackage.LEGACY_LITE:
                return t('account_dropdown.package_label.legacy_lite_package');
            case FeaturePackage.LEGACY_FULL:
                return t('account_dropdown.package_label.legacy_full_package');
        }
    }

    function shouldDisplayAccountUpgradeOption(featurePackage: String | undefined) {
        switch (featurePackage) {
            case FeaturePackage.LITE:
                return true;
            case FeaturePackage.CORE:
                return true;
            case FeaturePackage.PLUS:
                return false;
            case FeaturePackage.LEGACY_LITE:
                return true;
            case FeaturePackage.LEGACY_FULL:
                return true;
        }
    }

    const stopImpersonate = () => {
        setImpersonateId(undefined);
        setImpersonateUsername(undefined);
        navigate('/impersonate');
    }

    function handleManageAccountClick(): void {

        ReactGA.event("RT2-Menu-Link-RAMAccount");
        window.open("https://account.ramtracking.com", "_blank");

    }

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        let encodedPasswordUpdated = queryParams.get('passwordUpdated');

        if (encodedPasswordUpdated) {
            let passwordUpdated = decodeURIComponent(encodedPasswordUpdated);

            if (passwordUpdated) {
                auth.logOut();
                shutdown();
            }
        }
    }, [location]);

    return (
        <React.Fragment>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center'}}>
                <AppBarButton
                    id="account-menu-button"
                    icon={<AccountCircleIcon/>}
                    onClick={handleClick}
                    ariaControls={open ? 'account-menu' : undefined}
                    ariaHasPopup={true}
                    ariaExpanded={open ? true : undefined}
                />
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: "225px",
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 45,
                            height: 45,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <Box display="flex" flexDirection="column" alignItems="center"
                     sx={{
                         width: '100%',
                         paddingTop: '10px',
                         paddingBottom: '10px',
                     }}>
                    <Avatar
                        sx={{margin: '0px !important'}}>
                        {user?.firstName.charAt(0).toUpperCase() || <Person/>}
                    </Avatar>
                    <Typography variant="dropdownTitle">
                        {user?.firstName}
                    </Typography>
                    <Typography variant="dropdownBody">
                        {account?.name}
                    </Typography>
                    {account?.hasAccessPackageUpgrade && (
                        <Typography variant="dropdownCaption">
                            {getAccountPackageLabel(account?.featurePackage)}
                        </Typography>
                    )}
                </Box>
                {(account?.hasAccessPackageUpgrade && shouldDisplayAccountUpgradeOption(account?.featurePackage)) && (
                    <Box sx={{
                        width: '100%',
                        padding: '10px',
                        paddingTop: '0px',
                    }}>
                        <Button
                            id='account-menu-upgrade-options-button'
                            variant="outlined"
                            color='primary'
                            onClick={() => window.location.replace(`https://ram2ams.azurewebsites.net/api/RAMMarketing?code=FZDqro1VuczY2-HPWgRzRoqwDUCfh0rMvKg9jRfrCjhYAzFu01v-tw==&sf_campaign=Banner&utm_medium=Upgrade%20Options&utm_campaign=Upgrade&utm_source=From%20RAM%20Tracking&destinationURL=https%3A%2F%2Fwww2.ramtracking.com%2Fuk%2Fe%2Fupgrade-to-plus&account_id=${account?.id}&user_id=${user?.id}`)}
                            sx={{
                                borderRadius: '25px',
                                width: '100%',
                                padding: '4px',
                                fontSize: '12px',
                            }}>
                            {t('account_dropdown.upgrade_options')}
                        </Button>
                    </Box>
                )}

                <Divider/>

                <MenuItem
                    id='account-menu-profile-button'
                    component={Link}
                    to="/profile"
                    sx={{marginTop: '10px'}}>
                    <ListItemIcon sx={{color: colors.ram_purple, fontSize: '15px'}}>
                        <ManageAccounts fontSize="inherit"/>
                    </ListItemIcon>
                    <Typography variant="dropdownBody">
                        {t('account_dropdown.my_profile_option')}
                    </Typography>
                </MenuItem>

                <MenuItem
                    id='account-menu-account-button'
                    component="a" onClick={() => handleManageAccountClick()}
                >
                    <ListItemIcon sx={{color: colors.ram_purple, fontSize: '15px'}}>
                        <Settings fontSize="inherit"/>
                    </ListItemIcon>
                    <Typography variant="dropdownBody">
                        {t('account_dropdown.manage_account_option')}
                    </Typography>
                </MenuItem>

                <Divider/>

                <MenuItem
                    id='account-menu-webhook-button'
                    component={Link}
                    to="/webhooks"
                    sx={{marginTop: '10px'}}
                >
                    <ListItemIcon sx={{color: colors.ram_purple, fontSize: '15px'}}>
                        <WebhookIcon fontSize="inherit"/>
                    </ListItemIcon>
                    <Typography variant="dropdownBody">
                        {t('account_dropdown.webhooks_option')}
                    </Typography>
                </MenuItem>

                <MenuItem
                    id='account-menu-api-key-button'
                    component={Link}
                    to="/api-keys"
                >
                    <ListItemIcon sx={{color: colors.ram_purple, fontSize: '15px'}}>
                        <ApiIcon fontSize="inherit"/>
                    </ListItemIcon>
                    <Typography variant="dropdownBody">
                        {t('account_dropdown.api_key_option')}
                    </Typography>
                </MenuItem>

                <Divider/>

                <MenuItem
                    id='account-menu-licences-button'
                    component={Link}
                    to="/dataattribution">
                    <ListItemIcon sx={{color: colors.ram_purple, fontSize: '15px'}}>
                        <WorkspacePremiumIcon fontSize="inherit"/>
                    </ListItemIcon>
                    <Typography variant="dropdownBody">
                        {t('account_dropdown.data_licences_option')}
                    </Typography>
                </MenuItem>

                <Divider/>
                {user?.isAdmin && impersonateId === undefined &&
                    (<MenuItem
                        id='account-menu-impersonate-button'
                        component={Link}
                        to="/impersonate"
                        sx={{marginTop: '10px'}}
                    >
                        <ListItemIcon sx={{color: colors.ram_purple, fontSize: '15px'}}>
                            <SwapHorizIcon fontSize="inherit"/>
                        </ListItemIcon>
                        <Typography variant="dropdownBody">
                            {t('account_dropdown.impersonate_option')}
                        </Typography>
                    </MenuItem>)
                }

                {impersonateId &&
                    (<MenuItem
                        id='account-menu-stop-impersonate-button'
                        sx={{marginTop: '10px'}}
                        onClick={stopImpersonate}
                    >
                        <ListItemIcon sx={{color: colors.ram_purple, fontSize: '15px'}}>
                            <SwapHorizIcon fontSize="inherit"/>
                        </ListItemIcon>
                        <Typography variant="dropdownBody">
                            {t('account_dropdown.stop_impersonate_option')}
                        </Typography>
                    </MenuItem>)
                }

                <MenuItem
                    id='account-menu-change-password-button'
                    onClick={() => {
                        const redirectUrl = encodeURIComponent(window.location.href);
                        const changePasswordEndpoint = `${properties.changePasswordEndpoint}?redirectUrl=${redirectUrl}`;
                        window.location.replace(changePasswordEndpoint);
                    }}>
                    <ListItemIcon sx={{color: colors.ram_purple, fontSize: '15px'}}>
                        <LockReset fontSize="inherit"/>
                    </ListItemIcon>
                    <Typography variant="dropdownBody">
                        {t('account_dropdown.change_password_option')}
                    </Typography>
                </MenuItem>

                <MenuItem
                    id='account-menu-logout-button'
                    onClick={() => {
                        auth.logOut();
                        shutdown();
                        window.location.replace(
                            properties.authProviderLogoutEndpoint
                        );
                    }}>
                    <ListItemIcon sx={{color: colors.ram_purple, fontSize: '15px'}}>
                        <Logout fontSize="inherit"/>
                    </ListItemIcon>
                    <Typography variant="dropdownBody">
                        {t('account_dropdown.logout_option')}
                    </Typography>
                </MenuItem>

            </Menu>
        </React.Fragment>
    );
}
