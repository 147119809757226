import {Box, Typography} from '@mui/material';
import {colors} from "../../../themes/AppTheme";

interface IconValueTextProps {
    icon: JSX.Element;
    label: String;
    value: String | JSX.Element;
}

export function IconValueText(props: IconValueTextProps) {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "40px",
                minHeight: "40px",
            }}
            width="100%"
            display="flex"
            flexDirection="row"
        >
            <div
                style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: "50%",
                    minHeight: "30px",
                    minWidth: "30px",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {props.icon}
            </div>

            <Typography
                component="p"
                variant="cardbody"
                color="primary"
                style={{marginLeft: "5px"}}
            >
                {props.label}
            </Typography>

            <Typography
                component="span"
                variant="cardbody"
                color="primary"
                sx={{
                    marginLeft: "auto",
                    paddingLeft: "5px",
                    textAlign: "right",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: "75%",
                    transition: "max-width 0.1s ease, white-space 0.1s ease, background-color 0.1s ease",
                    "&:hover": {
                        maxWidth: "none",
                        whiteSpace: "normal",
                        backgroundColor: colors.white,
                        zIndex: 1,
                        position: "relative",
                    },

                }}
            >
                <b>{props.value}</b>
            </Typography>
        </Box>
    );
}

