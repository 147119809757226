import React, {useContext} from 'react';

import {useTranslation} from 'react-i18next';
import FullView from "../../layouts/FullView";
import {useAssetApi} from "../../hooks/assets/Assets";
import {Table, TableCell, TableRow} from "../../components/table/core/Table";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
import AssetTableSearchBar from "./components/AssetTableSearchBar";
import {FilterSortContext} from "../../providers/FilterSortProvider";
import ReactGA from "react-ga4";
import {Asset, AssetStatus} from "../../hooks/assets/dto/Asset";
import {filterAssetsBySearch} from "../../utils/AssetArrayFiltering";
import {AssetSortOptions, sortByAliasOrReg, sortByDriverName, sortByLastEventTime} from "../../utils/AssetArraySorting";
import {RowEditButton, RowViewButton} from "../../components/table/core/TableButtons";
import {useNavigate} from "react-router-dom";
import {buildAssigneeName, getAssetName} from "../../utils/AssetUtils";

export default function AssetPage() {
    return (
        <FullView content={<AssetPageContent/>}/>
    );
}

function AssetPageContent() {
    const {assets, loading} = useAssetApi({shouldLoadAll: true});

    const [filter, setFilter] = React.useState<string | undefined>(undefined);
    const { sort, groupFilter, typeFilter } = useContext(FilterSortContext);
    const [statusFilter] = React.useState<string>("all")
    const onSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setFilter(undefined);
        } else {
            ReactGA.event("search",  {search_term: "live-map-" + search});
            setFilter(search);
        }
    }

    const filterAssets = (assets: Array<Asset>) => {

        let filteredAssets = statusFilter !== 'all' ? assets.filter(asset => asset.status === statusFilter) : assets;
        filteredAssets = filterAssetsBySearch(filteredAssets, filter);

        switch (sort) {
            case AssetSortOptions.DRIVER_NAME:
                filteredAssets.sort(sortByDriverName);
                break;
            case AssetSortOptions.LAST_REPORTED:
                filteredAssets.sort(sortByLastEventTime);
                break;
            default:
                filteredAssets.sort(sortByAliasOrReg);
                break;
        }

        if (groupFilter !== "all") {
            filteredAssets = filteredAssets.filter(asset => asset.groupName === groupFilter);
        }
        if (typeFilter !== "all") {
            filteredAssets = filteredAssets.filter(asset => asset.type === typeFilter);
        }
        return filteredAssets;
    }

    return <div className="bg-base-100 w-full min-h-full rounded-md shadow-md p-4">
        <AssetTableSearchBar onSearchChange={onSearchChange} assets={assets || []} />
        <AssetTable assets={filterAssets(assets || [])} loading={loading}/>
    </div>
}

function AssetTable(props: Readonly<{assets?: Asset[], loading: boolean}>) {
    const navigate = useNavigate()

    const {t} = useTranslation();
    const {twentyFourHourEnabled} = useContext(UserPreferencesContext)

    const columns = [
        { id:'asset', content: t('asset_page.asset_table.asset')},
        { id:'assignee', content: t('asset_page.asset_table.assignee'), collapsible: true},
        { id:'group', content: t('asset_page.asset_table.group')},
        { id:'status', content: t('asset_page.asset_table.status')},
        { id:'location', content: t('asset_page.asset_table.location')},
        { id:'speed', content: t('asset_page.asset_table.speed'),collapsible: true},
        { id:'heading', content: t('asset_page.asset_table.heading'), collapsible: true},
        { id:'last-reported', content: t('asset_page.asset_table.last_reported'), collapsible: true},
        { id:'actions', content: t('asset_page.asset_table.actions')}
    ];

    function getAssetStatusText(status: AssetStatus) {
        if(status === "STOPPED") {
            return t('asset_status.stopped');
        } else if(status === "MOVING") {
            return t('asset_status.moving');
        } else if(status === "STATIONARY") {
            return t('asset_status.stationary');
        }
        return t('asset_status.inactive');
    }

    return (
        <Table
            id="asset-table"
            label="asset-table"
            columns={columns}
            disableCreateButton={true}
            rows={props.assets?.map((asset) => {
                return <TableRow key={asset.id}>
                    <TableCell>{getAssetName(asset)}</TableCell>
                    <TableCell collapsible={true}>{buildAssigneeName(asset, t) || "-"}</TableCell>
                    <TableCell>{asset.groupName || "-"}</TableCell>
                    <TableCell>{getAssetStatusText(asset.status)}</TableCell>
                    <TableCell>{asset.latestEvent?.location?.formattedAddress || t('asset_page.asset_table.no_location')}</TableCell>
                    <TableCell collapsible={true}>{asset.latestEvent ? asset.latestEvent.speed.value + asset.latestEvent.speed.units : "-"}</TableCell>
                    <TableCell collapsible={true}>{asset.latestEvent?.headingIndicator.toLowerCase()? t(`asset_directions.${asset.latestEvent?.headingIndicator.toLowerCase()}`) : "-"}</TableCell>
                    <TableCell collapsible={true}>{asset.latestEvent?.time.toLocaleString('en-GB', {year: 'numeric', month: "numeric", day: 'numeric', hour: "numeric", minute: "numeric", second: "numeric",  hour12: !twentyFourHourEnabled}) || "-"}</TableCell>
                    <TableCell>
                        <RowViewButton action={() => {navigate('/live-map?selected=' + asset.id);}}/>
                        <RowEditButton action={() => {
                            const selectedAsset = asset
                            navigate('/update-assets', {state: {selectedAsset}});}}/>
                    </TableCell>
                </TableRow>
            }) || []}
            loading={props.loading}/>
    )
}
