import {Box, Button, Divider, Grid, InputAdornment, Paper, TextField, Typography, useTheme} from "@mui/material";

import {useTranslation} from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";

import React, {useContext, useState} from "react";
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import dayjs, {Dayjs} from "dayjs";
import {useNavigate} from "react-router-dom";
import {FilterSortContext} from "../../providers/FilterSortProvider";
import {useAssetApi} from "../../hooks/assets/Assets";
import {AssetSortOptions, sortByDriverName, sortByLastEventTime} from "../../utils/AssetArraySorting";
import {Asset} from "../../hooks/assets/dto/Asset";
import {filterAssetsBySearch} from "../../utils/AssetArrayFiltering";
import {useLiveshareApi} from "../../hooks/liveshare/Liveshare";
import {LiveShareRequest} from "../../hooks/liveshare/dto/LiveShareRequest";
import {LiveShareLinksPeriod} from "../../hooks/liveshare/dto/LiveShareLink";
import LiveshareDayTable from "./components/LiveshareDayTable";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import SelectAssetTable from "../../components/table/shared/SelectAssetTable";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";

export default function CreateLivesharePage() {
    const theme = useTheme();
    const navigate = useNavigate()
    const {t} = useTranslation();

    const {languageCode} = useContext(UserPreferencesContext)

    const [selectedLinkPeriod, setSelectedLinkPeriod] = useState<LiveShareLinksPeriod>(LiveShareLinksPeriod.ALL);

    const [assetFilter, setAssetFilter] = React.useState<string | undefined>(undefined);
    const {sort} = useContext(FilterSortContext);

    const {createLink} = useLiveshareApi(false)

    const {assets, loading: loadingAssets} = useAssetApi({shouldLoadAll: true});
    const [selectedAssets, setSelectedAssets] = useState<number[]>([]);

    const [selectedStartTime, setSelectedStartTime] = useState<Dayjs>(dayjs().startOf('date'));
    const [selectedEndTime, setSelectedEndTime] = useState<Dayjs>(dayjs().endOf('date'));
    const [selectedExpireDate, setSelectedExpireDate] = useState<Dayjs | null>(null);

    const onSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setAssetFilter(undefined);
        } else {
            setAssetFilter(search);
        }
    }

    const filterAssets = (assets: Array<Asset>) => {
        let filteredAssets = filterAssetsBySearch(assets, assetFilter);

        switch (sort) {
            case AssetSortOptions.DRIVER_NAME:
                filteredAssets.sort(sortByDriverName);
                break;
            case AssetSortOptions.LAST_REPORTED:
                filteredAssets.sort(sortByLastEventTime);
                break;
            default:
                break;
        }

        return filteredAssets;
    }

    const handleCreateLiveshare = async () => {

        const startTime = selectedStartTime.format("HH:mm");
        const endTime = selectedEndTime.format("HH:mm");

        if (startTime >= endTime) {
            alert("Start time must be before end time");
            return;
        }

        await createLink(new LiveShareRequest({
            vehicleIds: selectedAssets,
            linkPeriod: selectedLinkPeriod,
            startTime: startTime,
            endTime: endTime,
            expiresAt: selectedExpireDate?.format("YYYY-MM-DDTHH:mm:ss").toString()
        }))
            .then((geofenceAlerts) => {
                navigate('/liveshare')
            })
            .catch((error) => {
                alert(t('liveshare.messages.liveshare_creation_failed'))
            })
    }

    const handleCancel = () => {
        navigate('/liveshare')
    }

    return (
        <Box
            component="main"
            sx={{
                // padding: '10px',
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.contentPage.background
            }}
        >
            <Grid container component={Paper} sx={{padding: '10px', height: '100%', width: '100%'}} elevation={3}>

                {/* Asset Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}}
                                    variant="title">{t("liveshare.admin.asset_selection")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("liveshare.admin.asset_selection_message")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Box sx={{
                            paddingBottom: '10px'
                        }}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                size="small"
                                label={t("liveshare.admin.asset_search_box_label")}
                                variant="outlined"
                                color="info"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                value={assetFilter}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    onSearchChange(event.target.value);
                                }}
                            />
                        </Box>
                        <Grid>
                            <SelectAssetTable
                                multiselect={true}
                                loading={loadingAssets}
                                assets={filterAssets(assets || [])}
                                selectedRows={selectedAssets}
                                setSelectedRows={setSelectedAssets}
                            />
                        </Grid>

                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Link Period Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}} variant="title">{t("liveshare.admin.link_period")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("liveshare.admin.select_link_period")}</Typography>

                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Grid>
                            <LiveshareDayTable linkPeriod={selectedLinkPeriod} setLinkPeriod={setSelectedLinkPeriod}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Start & End Time Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}} variant="title">{t("liveshare.admin.time_range")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("liveshare.admin.select_start_end_time")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={5} padding="20px">
                        <TimePicker label={t("liveshare.admin.start_time")}
                                    ampm={false}
                                    value={selectedStartTime} onChange={(newStartTime) => {
                            if (newStartTime != null) {
                                setSelectedStartTime(newStartTime)
                            } else {
                                setSelectedStartTime(dayjs().startOf('date'))
                            }
                        }}/>
                    </Grid>
                    <Grid item xs={12} md={4} lg={5} padding="20px">
                        <TimePicker label={t("liveshare.admin.end_time")}
                                    ampm={false}
                                    value={selectedEndTime} onChange={(newEndTime) => {
                            if (newEndTime != null) {
                                setSelectedEndTime(newEndTime)
                            } else {
                                setSelectedEndTime(dayjs().endOf('date'))
                            }
                        }}/>
                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Expire Date Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}} variant="title">{t("liveshare.admin.expiry_date")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("liveshare.admin.select_expiry_date")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={5} padding="20px">
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={languageCode}
                        >
                            <DateTimePicker disablePast
                                            ampm={false}
                                            label={t("liveshare.admin.expires")}
                                            value={selectedExpireDate}
                                            onChange={(newExpireDate) => {
                                                setSelectedExpireDate(newExpireDate);
                                            }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box
                            bottom="0px"
                            sx={{
                                padding: '10px',
                                display: 'flex', flexDirection: "row", justifyContent: "space-between"
                            }}>
                            <Button
                                variant="contained"
                                size='small'
                                color="secondary"
                                onClick={handleCancel}>
                                {t('button.cancel')}
                            </Button>
                            <Button
                                disabled={selectedAssets.length === 0 || !selectedLinkPeriod}
                                variant="contained"
                                size='small'
                                color="primary"
                                onClick={handleCreateLiveshare}
                            >
                                {t("button.create")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
