import {ListItemButton, ListItemText, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import LaunchIcon from "@mui/icons-material/Launch";
import React from "react";
import {Asset, AssetType} from "../../../../hooks/assets/dto/Asset";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import BuildIcon from "@mui/icons-material/Build";
import RvHookupIcon from "@mui/icons-material/RvHookup";
import {Location} from "../../../../hooks/search/Search";
import PlaceIcon from "@mui/icons-material/Place";
import {Geofence} from "../../../../hooks/geofences/dto/Geofence";


interface SearchResultProps {
    key: string;
    title: string;
    subtitle: string;
    link: string;
    onClick: () => void;
    icon: React.ReactNode;
}

function SearchResult(props: SearchResultProps) {
    return (
        <ListItemButton
            key={props.key}
            component={Link}
            onClick={props.onClick}
            to={props.link}
            sx={{borderRadius: '5px', margin: '0px', padding: '0px'}}
            dense={true}
        >
            <ListItemIcon style={{minWidth: "0px", paddingLeft: "10px", paddingRight: "10px"}}>
                {props.icon}
            </ListItemIcon>
            <ListItemText
                disableTypography
                sx={{margin: '0px'}}
                primary={<Typography variant="body" sx={{paddingBottom: '0px', fontWeight: 'bold'}}>{props.title}</Typography>}
                secondary={<Typography variant="caption" sx={{paddingTop: '0px'}}>{props.subtitle}</Typography>}/>
            <ListItemIcon style={{minWidth: "0px", paddingLeft: "10px", paddingRight: "10px"}}>
                <LaunchIcon fontSize={'small'}/>
            </ListItemIcon>
        </ListItemButton>
    )
}

interface AssetSearchResultProps {
    key: string;
    onClick: () => void;
    asset: Asset;
}

export function AssetSearchResult(props: AssetSearchResultProps) {

    let displayName = props.asset.alias ? props.asset.alias + " - " + props.asset.identificationMark : props.asset.identificationMark;
    let resultTitle = displayName + " (" + props.asset.type + ")";
    let resultSubtitle = props.asset.assignee.name;
    let link = "/live-map?selected=" + props.asset.id;

    return <SearchResult
        key={props.key}
        title={resultTitle}
        subtitle={resultSubtitle}
        link={link}
        onClick={props.onClick}
        icon={getIconForAssetType(props.asset.type)}
    />
}

function getIconForAssetType(type: AssetType) {
    switch (type) {
        case AssetType.CAR:
            return <DirectionsCarFilledIcon fontSize={'small'}/>;
        case AssetType.TRUCK:
            return <LocalShippingIcon fontSize={'small'}/>;
        case AssetType.VAN:
            return <AirportShuttleIcon fontSize={'small'}/>;
        case AssetType.PLANT:
            return <AgricultureIcon fontSize={'small'}/>;
        case AssetType.MOTORCYCLE:
            return <TwoWheelerIcon fontSize={'small'}/>;
        case AssetType.GENERATOR:
            return <OfflineBoltIcon fontSize={'small'}/>;
        case AssetType.EQUIPMENT:
            return <BuildIcon fontSize={'small'}/>;
        case AssetType.TRAILER:
            return <RvHookupIcon fontSize={'small'}/>;
        default:
            return <DirectionsCarFilledIcon fontSize={'small'}/>;
    }
}

interface LocationSearchResultProps {
    key: string;
    onClick: () => void;
    location: Location;
}

export function LocationSearchResult(props: LocationSearchResultProps) {
    let title = props.location.displayName;
    let subtitle = props.location.latitude + ", " + props.location.longitude;
    let link = "/live-map?locationName=" + props.location.displayName + "&locationLongitude=" + props.location.longitude + "&locationLatitude=" + props.location.latitude;
    let icon = <PlaceIcon fontSize={'small'}/>;


    return (<SearchResult
        key={props.key}
        title={title}
        subtitle={subtitle}
        link={link}
        onClick={props.onClick}
        icon={icon}
    />)
}

interface GeofenceSearchResultProps {
    key: string;
    onClick: () => void;
    geofence: Geofence;
}

export function GeofenceSearchResult(props: Readonly<GeofenceSearchResultProps>) {

    let displayName = props.geofence.name;
    let resultTitle = displayName;
    let subtitle = props.geofence.category?.name ?? "";
    let link = "/live-map?selectedGeofence=" + props.geofence.id;
    let icon = <PlaceIcon fontSize={'small'}/>;

    return <SearchResult
        key={props.key}
        title={resultTitle}
        subtitle={subtitle}
        link={link}
        onClick={props.onClick}
        icon={icon}
    />
}
