import {Box, Typography} from '@mui/material';
import * as React from 'react';
import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Asset} from '../../../hooks/assets/dto/Asset';
import PersonIcon from '@mui/icons-material/Person';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShareIcon from '@mui/icons-material/Share';
import ExploreIcon from '@mui/icons-material/Explore';
import {Popup, useMap} from 'react-leaflet';
import './AssetMarkerPopup.css'
import {Battery4Bar, Speed, Thermostat} from '@mui/icons-material';
import {IconValueText} from './IconValueText';
import {UserPreferencesContext} from "../../../providers/UserPreferencesProvider";
import ConfirmationDialog from "../../../components/dialog/ConfirmationDialog";
import UrlDialog from "../../../components/dialog/UrlDialog";
import {LiveShareRequest} from "../../../hooks/liveshare/dto/LiveShareRequest";
import {LiveShareLinksPeriod} from "../../../hooks/liveshare/dto/LiveShareLink";
import dayjs from "dayjs";
import {useLiveshareApi} from "../../../hooks/liveshare/Liveshare";
import {getTomorrowDate} from "../../../utils/DateUtils";
import {getParameterValue} from "../../../components/assetpanel/AssetDetail";
import SignalWifiStatusbarConnectedNoInternet4Icon from "@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4";
import {buildAssigneeName} from "../../../utils/AssetUtils";
import {languageToLocal} from "../../../utils/LocalUtils";


interface AssetMarkerPopupProps {
    asset: Asset;
    selectedAsset: string | undefined;
    setSelectedAsset: React.Dispatch<React.SetStateAction<string | undefined>>;
}

function AssetMarkerPopup(props: AssetMarkerPopupProps) {
    const {twentyFourHourEnabled, languageCode} = useContext(UserPreferencesContext)
    const map = useMap();
    const {t} = useTranslation();
    const {createLink} = useLiveshareApi(false);


    const [showCreateLiveLinkDialog, setShowCreateLiveLinkDialog] = React.useState<boolean>(false)
    const [showUrlDialog, setShowUrlDialog] = React.useState<boolean>(false)
    const [liveLink, setLiveLink] = React.useState<string>("")

    const handleCreateLiveLinkClick = async () => {
        setShowCreateLiveLinkDialog(false);

        await createLink(new LiveShareRequest({
            vehicleIds: [props.asset.id],
            linkPeriod: LiveShareLinksPeriod.ALL,
            startTime: dayjs().startOf("date").format("HH:mm").toString(),
            endTime: dayjs().endOf("date").format("HH:mm").toString(),
            expiresAt: getTomorrowDate().toString()
        })).then((liveShareLink) => {
            setLiveLink(liveShareLink.url)
            setShowUrlDialog(true);
        }).catch((error) => {
            setShowUrlDialog(false);
        })
    }

    function handleCopyClick(link: string) {
        navigator.clipboard.writeText(link)
            .then(() => {

            })
            .catch(error => {
                console.error('Error copying text: ', error);
            });
    }

    function getTextForStatus(status: string) {
        switch (status) {
            case 'MOVING':
                return t('asset_status.moving');
            case 'STATIONARY':
                return t('asset_status.stationary');
            case 'STOPPED':
                return t('asset_status.stopped');
            default:
                return t('asset_status.moving');
        }
    }

    const geofenceName = props.asset.latestEvent && props.asset.latestEvent.location.geofences[0] ? props.asset.latestEvent.location.geofences[0].geofenceName + ", " : "";

    return (
        <Popup className='asset-popup' closeButton={false}
               eventHandlers={{
                   popupopen: () => {
                       if (props.asset.latestEvent !== undefined) {
                           map.setView([props.asset.latestEvent.location.rawCoordinates.latitude, props.asset.latestEvent.location.rawCoordinates.longitude], map.getZoom(), {animate: true});
                       }
                   }

               }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '400px',
            }}>
                <div className="w-full flex flex-row">
                    <div className="flex flex-col">
                        <Typography
                            sx={{display: "block", width: "100%", paddingBottom: "0px"}}
                            component="div"
                            variant="cardtitle"
                            color="text.primary"
                        >
                            {props.asset.alias || props.asset.identificationMark}
                        </Typography>

                        <Typography
                            sx={{display: "block", width: "100%"}}
                            component="div"
                            variant="cardbody"
                            color="text.secondary"
                        >
                            {getTextForStatus(props.asset.status)}
                        </Typography>
                    </div>
                    <button
                        className={"secondary-button mt-0 ml-auto"}
                        onClick={() => {
                            setShowCreateLiveLinkDialog(true);
                        }}>
                        <ShareIcon/> {t('asset_card.btn_share')}
                    </button>
                </div>
                <hr style={{
                    borderTop: "2px solid orange",
                    width: "calc(100% + 45px)",
                    margin: "0",
                    marginTop: "6px",
                    marginBottom: "6px",
                    marginLeft: "-20px"
                }}/>

                <IconValueText
                    icon={<Speed color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_card.speed') as String}
                    value={props.asset.latestEvent?.speed.value + ' ' + props.asset.latestEvent?.speed.units}
                />

                <IconValueText
                    icon={<ExploreIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_card.heading') as String}
                    value={props.asset.latestEvent?.headingIndicator ? t(`asset_directions.${props.asset.latestEvent?.headingIndicator.toLowerCase()}`) as string : ""}
                />

                <IconValueText
                    icon={<PersonIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_card.driver') as String}
                    value={buildAssigneeName(props.asset, t)}
                />


                {props.asset.latestEvent?.batteryPercentage !== undefined && props.asset.latestEvent?.batteryPercentage !== null ? (
                    <IconValueText
                        icon={<Battery4Bar color="primary" style={{fontSize: "18px"}}/>}
                        label={t('asset_card.battery_percentage') as String}
                        value={getParameterValue(props.asset.latestEvent.batteryPercentage) + '%'}
                    />
                ) : <></>}

                {props.asset.latestEvent?.temperature1 !== undefined && props.asset.latestEvent?.temperature1 !== null ? (
                    <IconValueText
                        icon={<Thermostat color="primary" style={{fontSize: "18px"}}/>}
                        label={t('asset_card.temperature1') as String}
                        value={getParameterValue(props.asset.latestEvent.temperature1) + '°C'}
                    />
                ) : <></>}

                {props.asset.latestEvent?.temperature2 !== undefined && props.asset.latestEvent?.temperature2 !== null ? (
                    <IconValueText
                        icon={<Thermostat color="primary" style={{fontSize: "18px"}}/>}
                        label={t('asset_card.temperature2') as String}
                        value={getParameterValue(props.asset.latestEvent.temperature2) + '°C'}
                    />
                ) : <></>}

                <IconValueText
                    icon={<WorkspacesIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_card.group') as String}
                    value={props.asset.groupName || 'Not Assigned'}
                />

                <IconValueText
                    icon={<LocationOnIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_card.address') as String}
                    value={getParameterValue(geofenceName + props.asset.latestEvent?.location.formattedAddress)}
                />

                <IconValueText
                    icon={<AccessTimeIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_card.last_event_time') as String}
                    value={props.asset.latestEvent?.time.toLocaleString(languageToLocal(languageCode), {
                        weekday: 'short',
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: !twentyFourHourEnabled
                    }) || ""}
                />

                <IconValueText
                    icon={<SignalWifiStatusbarConnectedNoInternet4Icon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_details.gps_signal') as String}
                    value={props.asset.latestEvent?.location?.goodGpsFix ?
                        t('asset_details.good_gps_signal_label') as String : t('asset_details.poor_gps_signal_label') as String}
                />

                <Box
                    sx={{
                        width: "calc(100% + 45px)",
                        height: "2px",
                        backgroundColor: "#d2caca2b",
                        margin: "0",
                        marginLeft: "-20px",
                        marginTop: "10px",
                    }}
                />

                <div className={"flex flex-col"}>
                    {props.selectedAsset !== props.asset.id.toString() && (
                        <button
                            className="primary-button w-full"
                            onClick={(event) => {
                                props.setSelectedAsset(props.asset.id.toString());
                                if (props.asset.latestEvent !== undefined) {
                                    map.setView([props.asset.latestEvent.location.rawCoordinates.latitude, props.asset.latestEvent.location.rawCoordinates.longitude], 18);
                                }
                            }}>
                            {t('asset_card.btn_see_more')}
                        </button>
                    )}

                    <button
                        className="primary-button w-full"
                        onClick={() => {
                            if (props.asset?.latestEvent?.location?.rawCoordinates?.latitude && props.asset?.latestEvent?.location?.rawCoordinates?.longitude) {
                                const lat = props.asset?.latestEvent?.location?.rawCoordinates?.latitude;
                                const lng = props.asset?.latestEvent?.location?.rawCoordinates?.longitude;
                                window.open('https://maps.google.com/maps?q=&layer=c&cbll=' + lat + ',' + lng + '', 'name', 'width=800,height=600')
                            }
                        }}>
                        {t('asset_card.btn_street_view')}
                    </button>

                    <button
                        className="primary-button w-full"
                        onClick={() => {
                            if (props.asset?.latestEvent?.location?.rawCoordinates?.latitude && props.asset?.latestEvent?.location?.rawCoordinates?.longitude) {
                                const lat = props.asset?.latestEvent?.location?.rawCoordinates?.latitude;
                                const lng = props.asset?.latestEvent?.location?.rawCoordinates?.longitude;
                                window.open('https://www.google.com/maps/dir/?api=1&destination=' + lat + ',' + lng + '', 'name', 'width=800,height=600')
                            }
                        }}>
                        {t('asset_card.btn_directions')}
                    </button>
                </div>
                <ConfirmationDialog open={showCreateLiveLinkDialog}
                                    title={t('asset_card.create_dialog.title')}
                                    description={t('asset_card.create_dialog.description')}
                                    confirmText={t('button.create')} cancelText={t('button.cancel')}
                                    onConfirm={handleCreateLiveLinkClick}
                                    onCancel={() => setShowCreateLiveLinkDialog(false)}/>
                <UrlDialog open={showUrlDialog}
                           title={t('asset_card.url_dialog.title')}
                           description={t('asset_card.url_dialog.description')}
                           copyText={t('button.copy')} cancelText={t('button.cancel')}
                           link={liveLink}
                           onCopy={handleCopyClick}
                           onCancel={() => setShowUrlDialog(false)}/>
            </Box>
        </Popup>
    );
}

export default AssetMarkerPopup;
