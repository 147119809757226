import usePlatformFetch from "../shared/PlatformFetch";
import PropertiesLoader from "../../properties/PropertiesLoader";
import {mapToReportResponse, mapToReportTypeArray} from "./ReportMapper";
import {ReportRequest} from "./dto/ReportRequest";

const properties = PropertiesLoader();


export function useReportsApi(shouldPoll?: boolean, pollFrequency?: number) {
    const {platformFetch, checkOk} = usePlatformFetch();

    const getAvailableReports = async () => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "report/v1/available-reports",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToReportTypeArray(json))
                .then(reportTypes => resolve(reportTypes))
                .catch((error) => {
                    console.error("Failed to get available: " + error)
                    reject(error)
                });
        })
    }

    const generateReport = async (reportRequest: ReportRequest) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "report/v1/generate-report?async=false",
                'post',
                JSON.stringify(reportRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToReportResponse(json))
                .then(reportResponse => resolve(reportResponse))
                .catch((error) => {
                    console.error("Failed to generate report: " + error)
                    reject("error")
                });
        })
    }

    return {getAvailableReports, generateReport}
}
