import { Box, List, Typography } from "@mui/material";
import { Asset, AssetEvent, AssetStatus } from "../../../../hooks/assets/dto/Asset";

import ListSectionHeading from "../../../../components/leftpanel/ListSectionHeading";

import "react-datepicker/dist/react-datepicker.css";

import React, { useContext } from "react";
import { Journey } from "../../../../hooks/assets/dto/Journey";
import L, { LatLngExpression, Map } from "leaflet";
import RouteReplayJourneyDetailCard from "./RouteReplayJourneyDetailCard";
import LoadingSpinner from "../../../../components/loadingspinner/LoadingSpinner";
import LeftPanelButtonBar from "../../../../components/leftpanel/LeftPanelButtonBar";
import { IconValueText } from "../../../map/components/IconValueText";
import PlaceIcon from "@mui/icons-material/Place";
import SpeedIcon from "@mui/icons-material/Speed";
import LocationOffIcon from '@mui/icons-material/LocationOff';
import { useTranslation } from "react-i18next";
import {getParameterValue} from "../../../../components/assetpanel/AssetDetail";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from "@mui/lab";
import { StatusColors, getStatusColors } from "../../../../themes/AppTheme";
import { UserPreferencesContext } from "../../../../providers/UserPreferencesProvider";
import { useAssetApi } from "../../../../hooks/assets/Assets";
import ReactGA from "react-ga4";
import {getColorByAssetEvent} from "../../../../utils/ColorUtils";

interface RouteReplayJourneyDetailProps {
    mapref: React.RefObject<Map>;
    setMarkers: Function;
    asset: Asset;
    journey: Journey;
    onBackPressed: Function;
}

export default function RouteReplayJourneyDetail(props: RouteReplayJourneyDetailProps) {
    const { t } = useTranslation();

    const { getAssetEventsBetweenDates } = useAssetApi({shouldLoadAll:true});
    const { colorPalette } = useContext(UserPreferencesContext)
    const statusColors = getStatusColors(colorPalette);
    const [loaded, setLoaded] = React.useState<boolean>(false)
    const [events, setEvents] = React.useState<AssetEvent[]>([])

    async function loadJourneyEvents() {
        const events = await getAssetEventsBetweenDates(props.asset.id, props.journey.startTime, props.journey.endTime, true) as Array<AssetEvent>
        if (events.length !== 0) {
            setEvents(events)
            setLoaded(true)
            drawPolyLineRouteAndMarkersOnMap(events)
        }
    }

    function drawPolyLineRouteAndMarkersOnMap(events: Array<AssetEvent>) {

        if (props.mapref.current) {
            const latlngs = events.map(event => [event.location.snappedCoordinates.latitude, event.location.snappedCoordinates.longitude])
            const polyline = L.polyline(latlngs as LatLngExpression[], { color: '#21994D' }).addTo(props.mapref.current);
            props.setMarkers(events)
            props.mapref.current.fitBounds(polyline.getBounds());
        }

    }

    function clearRouteFromMap() {
        if (props.mapref.current) {
            props.setMarkers([])
            props.mapref.current.eachLayer(function (layer) {
                if (layer instanceof L.Polyline) {
                    props.mapref.current?.removeLayer(layer);
                }
            });
        }
    }

    function handleBackPress() {
        ReactGA.event("RT2-RouteReplay-BacktoJourneys");
        clearRouteFromMap();
        props.onBackPressed();
    }

    React.useEffect(() => {
        loadJourneyEvents();
    }, []);

    return (
        <Box component="main" sx={{
            flexGrow: 1, p: 0,
            height: 'calc(-67px + 100vh)', overflow: "hidden",
            overflowY: "scroll",
        }}>
            <List
                dense={true}
                sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
                <LeftPanelButtonBar onBackPressed={handleBackPress} />


                <Box sx={{ width: '100%', padding: '10px' }}>
                    <Typography
                        component="span"
                        variant="cardtitle"
                        color="primary">
                        {props.asset.alias || props.asset.identificationMark}
                    </Typography>

                    <IconValueText
                        icon={<SpeedIcon color="primary" style={{ fontSize: "18px" }} />}
                        label={t('route_replay.total_route_length') as String}
                        value={getParameterValue(props.journey.distance.value.toString() + " " + props.journey.distance.units.toString())}
                    />

                    <IconValueText
                        icon={<LocationOffIcon color="primary" style={{ fontSize: "18px" }} />}
                        label={t('route_replay.from') as String}
                        value={getParameterValue(props.journey.startAddress)}
                    />

                    <IconValueText
                        icon={<PlaceIcon color="primary" style={{ fontSize: "18px" }} />}
                        label={t('route_replay.to') as String}
                        value={getParameterValue(props.journey.endAddress)}
                    />

                    <IconValueText
                        icon={<PlayArrowIcon color="primary" style={{ fontSize: "18px" }} />}
                        label={t('route_replay.start_time') as String}
                        value={getParameterValue(props.journey.startTime.toLocaleTimeString())}
                    />

                    <IconValueText
                        icon={<StopIcon color="primary" style={{ fontSize: "18px" }} />}
                        label={t('route_replay.end_time') as String}
                        value={getParameterValue(props.journey.endTime.toLocaleTimeString())}
                    />
                </Box>


                <Box sx={{ width: '100%' }}>
                    <ListSectionHeading title={t("route_replay.journey")} />

                    {!loaded ? (<LoadingSpinner />) : <></>}

                    {loaded && events.length === 0 ? (<>No Events To Load</>) : (<></>)}

                    <Timeline sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                        },
                    }}>
                        {loaded && events.length > 0 ? events!!.map((event, index) => {
                            const isLastEvent = index === events.length - 1;

                            return (
                                <TimelineItem position="right">
                                    <TimelineSeparator>
                                        <TimelineDot variant="outlined" sx={{
                                            padding: '0px',
                                            margin: '0px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                            backgroundColor: 'transparent',
                                            borderColor: 'transparent'
                                        }}>
                                            {getIcon(event, statusColors)}
                                        </TimelineDot>
                                        {isLastEvent ? (<></>) : (<TimelineConnector />)}

                                    </TimelineSeparator>
                                    <TimelineContent sx={{ padding: '0px' }}><RouteReplayJourneyDetailCard key={event.time.toISOString()} event={event} mapRef={props.mapref}/></TimelineContent>
                                </TimelineItem>)
                        }
                        ) : (<></>)}
                    </Timeline>
                </Box>
            </List>
        </Box>

    );
}

function getIcon(event: AssetEvent, statusColors: StatusColors) {
    if (event.status === AssetStatus.MOVING) {
        return <PlayCircleFilledIcon style={{ fontSize: "21px", color: getColorByAssetEvent(event, statusColors) }} />;
    } else if (event.status === AssetStatus.STOPPED || event.status === AssetStatus.STATIONARY) {
        return <StopCircleIcon style={{ fontSize: "21px", color: getColorByAssetEvent(event, statusColors) }} />
    }
}
