import {Box, ListItem, Typography} from '@mui/material';
import {AssetEvent} from '../../../../hooks/assets/dto/Asset';
import {getStatusColors} from "../../../../themes/AppTheme";
import React, {useContext} from "react";
import {UserPreferencesContext} from "../../../../providers/UserPreferencesProvider";
import {useTranslation} from "react-i18next";
import {Map} from "leaflet";
import {getTranslatedEventName} from "../../../../utils/AssetEventUtils";
import {getColorByAssetEvent} from "../../../../utils/ColorUtils";

interface RouteReplayJourneyDetailCardProps {
    event: AssetEvent;
    mapRef: React.RefObject<Map>;
}

function RouteReplayJourneyDetailCard(props: RouteReplayJourneyDetailCardProps) {

    const {colorPalette} = useContext(UserPreferencesContext)
    const statusColors = getStatusColors(colorPalette);
    const {t} = useTranslation();
    const navigateToMarker = () => {
        const map = props.mapRef.current
        if (!map) {
            return
        }
        map.setView([props.event.location.snappedCoordinates.latitude, props.event.location.snappedCoordinates.longitude], 15, {animate: true});
    }

    return (
        <>
            <ListItem
                alignItems="flex-start"
            >

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: '5px',
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        cursor: "pointer",
                    }}
                    onClick={navigateToMarker}
                >

                    <Typography
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%"
                        }}
                        component="span"
                        variant="cardbody"
                        color="text.primary"
                    >
                        <span><b
                            style={{color: getColorByAssetEvent(props.event, statusColors)}}>{t(`asset_status.${props.event.status.toLowerCase()}`)}</b></span>
                        <span style={{fontStyle: "italic"}}>{props.event.time.toLocaleTimeString()}</span>
                    </Typography>

                    <Typography
                        sx={{display: "block", width: "100%"}}
                        component="span"
                        variant="cardbody"
                        color="text.primary"
                    >
                        <span><b>{t("route_replay.event")}: </b> {getTranslatedEventName(props.event, t)}</span>
                    </Typography>

                    <Typography
                        sx={{display: "block", width: "100%"}}
                        component="span"
                        variant="cardbody"
                        color="text.primary"
                    >
                        <span><b>{t("route_replay.address")}: </b> {props.event.location.formattedAddress}</span>
                    </Typography>

                </Box>

            </ListItem>
        </>
    );
}

export default RouteReplayJourneyDetailCard;

