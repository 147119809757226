import { Box, Button, Divider, Grid, MenuItem, Tooltip, Typography, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { UserPreferencesContext } from '../../../../providers/UserPreferencesProvider';
import TextInput from '../../../../components/form/TextInput';
import SelectInput from '../../../../components/form/SelectInput';
import { UserAccountContext } from '../../../../providers/UserAccountProvider';
import Timezones from '../../../../utils/Timezones';
import { useTranslation } from 'react-i18next';
import { SsoUser } from '../../../../hooks/user/dto/SsoUser';
import isEmailValid from '../../../../utils/EmailValidator';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import useUserApi from '../../../../hooks/user/User';
import { ImpersonateUserContext } from '../../../../providers/ImpersonateUserProvider';

interface UserInformationPageProps {
}

export default function UserInformationPage(props: UserInformationPageProps) {
    const { t } = useTranslation();
    const theme = useTheme();
    const auth: IAuthContext = useContext(AuthContext)
    const { impersonateUsername } = useContext(ImpersonateUserContext);
    const { updateUserProfile } = useUserApi();
    const { user, setUser } = useContext(UserAccountContext)

    const timezones = Timezones.map((string) => (<MenuItem key={"tz-" + string} value={string}>{string}</MenuItem>));

    const languages = [
        (
          <MenuItem key="en-lang-item" value={'en'}>
            {t('languages.english')}
          </MenuItem>
        ),
        (
            <MenuItem key="fr-lang-item" value={'fr'}>
                {t('languages.french')}
            </MenuItem>
        )
    ];

    const clockTypes = [
        (<MenuItem id="clock-type-default" key="clock-type-default" value={'default'}>{t('setup_wizard.customise_experience_step.clock_type.options.24_hour')}</MenuItem>),
        (<MenuItem id="clock-type-twelve-hour" key="clock-type-twelve-hour" value={'12_hour'}>{t('setup_wizard.customise_experience_step.clock_type.options.12_hour')}</MenuItem>),
    ];

    const [editMode, setEditMode] = useState<boolean>(false);

    const [firstName, setFirstName] = useState<string>(user?.firstName || '');
    const [lastName, setLastName] = useState<string>(user?.lastName || '');
    const [email, setEmail] = useState<string>(user?.email || '');
    const { languageCode, setLanguageCode, twentyFourHourEnabled, setTwentyFourHourEnabled } = useContext(UserPreferencesContext);
    const [tempLanguageCode, setTempLanguageCode] = useState<string>(languageCode);
    const [tempClockType, setTempClockType] = useState<string>(twentyFourHourEnabled ? 'default' : '12_hour');
    const [timezone, setTimezone] = useState(user?.timezone || 'Europe/London');

    async function submitChanges() {
        if (firstName !== '' && lastName !== '' && email !== '' && isEmailValid(email)) {
            try {
                let user = await updateUserProfile({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    timezone: timezone,
                })

                if (!user) {
                    auth.logOut();
                }

                setUser(user as SsoUser)
                setLanguageCode(tempLanguageCode);
                setEditMode(false);
                setTwentyFourHourEnabled(tempClockType === 'default');
            } catch (error) {
                console.error(
                    "Failed to fetch update user profile. Logging out. Error: " + error
                );
                auth.logOut();
            }
        }
    }

    function discardChanges() {
        setFirstName(user?.firstName || '');
        setLastName(user?.lastName || '');
        setEmail(user?.email || '');
        setTempLanguageCode(languageCode);
        setTimezone(user?.timezone || 'Europe/London');
        setEditMode(false);
    }


    return (
        <Box sx={{
            display: 'flex', flexDirection: "column", flexGrow: 1, justifyContent: "space-between"
        }}>
            <Box sx={{
                padding: '30px',
                paddingTop: '10px',
            }}>
                <Typography variant="header" sx={{ paddingBottom: "30px" }}>
                    {t('profile_page.user_information.title')}
                </Typography>
                <Divider />
                <Grid container padding="10px" spacing={2.5}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Typography variant="title">
                            {t('profile_page.user_information.personal_information.title')}
                        </Typography>
                        <Typography variant="body">
                            {t('profile_page.user_information.personal_information.description')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                        <TextInput editMode={editMode} isEmail={false} label={t('profile_page.user_information.personal_information.first_name')} required={true} placeholder={t('profile_page.user_information.personal_information.first_name')} value={firstName} onChange={setFirstName} />
                        <TextInput editMode={editMode} isEmail={false} label={t('profile_page.user_information.personal_information.last_name')} required={true} placeholder={t('profile_page.user_information.personal_information.last_name')} value={lastName} onChange={setLastName} />
                        <TextInput editMode={editMode} isEmail={true} label={t('profile_page.user_information.personal_information.email')} required={true} placeholder={t('profile_page.user_information.personal_information.email')} value={email} onChange={setEmail} />
                    </Grid>
                </Grid>
                { !impersonateUsername && 
                <>
                    <Divider />
                    <Grid container padding="10px" spacing={2.5}>
                        <Grid item xs={12} md={4} lg={4}>
                            <Typography variant="title">
                                {t('profile_page.user_information.localisation.title')}
                            </Typography>
                            <Typography variant="body">
                                {t('profile_page.user_information.localisation.description')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8} lg={8}>
                            <SelectInput key="language-select"
                                editMode={editMode}
                                label={t('profile_page.user_information.localisation.language')}
                                required={true}
                                items={languages}
                                value={tempLanguageCode}
                                onChange={setTempLanguageCode} />

                            <SelectInput key="timezone-select"
                                editMode={editMode}
                                label={t('profile_page.user_information.localisation.timezone')}
                                required={true}
                                items={timezones}
                                value={timezone}
                                onChange={setTimezone} />

                            <SelectInput key="clock-type-select"
                                editMode={editMode}
                                label={t('profile_page.user_information.localisation.clock_type')}
                                required={true}
                                items={clockTypes}
                                value={tempClockType}
                                onChange={(choice) => setTempClockType(choice)} />
                        </Grid>
                    </Grid>
                </> 
                }
            </Box>

            {editMode && (
                <Box
                    bottom="0px"
                    sx={{
                        padding: '10px',
                        borderTop: '1px solid #808080',
                        display: 'flex', flexDirection: "row", justifyContent: "space-between"
                    }}>
                    <Button
                        variant="contained"
                        size='small'
                        color="secondary"
                        onClick={() => {
                            discardChanges()
                        }}>
                        {t('button.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        size='small'
                        color="primary"
                        onClick={async () => {
                            await submitChanges();
                        }}
                    >
                        {t('button.save')}
                    </Button>
                </Box>)}

            {!editMode && (
                <Box
                    bottom="0px"
                    sx={{
                        padding: '10px',
                        borderTop: '1px solid ' + theme.palette.contentPage.footerDivider,
                        display: 'flex', flexDirection: "row", justifyContent: "space-between"
                    }}>
                    <Box />
                    <Button
                        variant="contained"
                        size='small'
                        color="primary"
                        onClick={() => {
                            setEditMode(true);
                        }}>
                        {t('button.edit')}
                    </Button>
                </Box>)
            }
        </Box>
    );
};





