import React from 'react';
import {useTranslation} from 'react-i18next';
import FullView from "../../layouts/FullView";
import {Table, TableCell, TableRow} from "../../components/table/core/Table";
import {RowDeleteButton} from "../../components/table/core/TableButtons";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog";
import FilterBar from "../../components/filterbar/FilterBar";
import {useApiKeyApi} from "../../hooks/apiKeys/ApiKeys";
import {ApiKey} from "../../hooks/apiKeys/dto/ApiKey";
import {Typography} from "@mui/material";
import {useIntercom} from "react-use-intercom";

export default function ApiKeyPage() {
    return (
        <FullView content={<ApiKeyPageContent/>}/>
    );
}

function ApiKeyPageContent() {
    const {t} = useTranslation();
    const {apiKeys, getAllApiKeys, loading} = useApiKeyApi({shouldLoadAll: true});
    const [searchInput, setSearchInput] = React.useState<string>("");

    function getFilteredApiKeys() {
        if (apiKeys === null) {
            return [];
        } else if (searchInput === undefined || searchInput === "") {
            return apiKeys || [];
        } else {
            const lowerCaseFilter = searchInput.toLowerCase();
            return apiKeys.filter(webhook =>
                webhook.clientId.toLowerCase().includes(lowerCaseFilter)
            );
        }
    }

    return <div className="bg-base-100 w-full min-h-full rounded-md shadow-md p-4">
        <FilterBar onSearchChange={setSearchInput} filter={searchInput} label={t("api_key_page.search_box")}/>
        <ApiKeyTable apiKeys={getFilteredApiKeys()} loading={loading} updateTrigger={getAllApiKeys}/>
    </div>
}

function ApiKeyTable(props: Readonly<{ apiKeys?: ApiKey[], loading: boolean, updateTrigger: () => void }>) {

    const {showArticle} = useIntercom();
    const {createApiKey, deleteApiKey} = useApiKeyApi();

    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)

    const {t} = useTranslation();

    const columns = [
        {id: 'client-id', content: t('api_key_page.api_key_table.client_id')},
        {id: 'client-secret', content: t('api_key_page.api_key_table.client_secret')},
        {id: 'webhook-actions', content: t('api_key_page.api_key_table.actions')}
    ];

    async function handleDelete() {
        await deleteApiKey()
            .catch((error) => {
                alert(t('api_key_page.api_key_messages.api_key_delete_failed'))
            })

        setShowDeleteDialog(false);
        props.updateTrigger();
    }

    async function handleCreate() {
        await createApiKey()
            .catch((error) => {
                alert(t('api_key_page.api_key_messages.api_key_create_failed'))
            })

        props.updateTrigger();
    }

    return (
        <React.Fragment>
            <Typography variant="dropdownBody">
                {t('api_key_page.api_key_messages.api_key_info')}{' '}
                <button
                    onClick={() => showArticle(5514025)}
                    style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        background: 'none',
                        border: 'none',
                        padding: 0,
                        font: 'inherit',
                        color: 'inherit'
                    }}
                >{t("api_key_page.api_key_messages.here")}
                </button>
            </Typography>
            <Table
                id="api-key-table"
                label="api-key-table"
                columns={columns}
                rows={props.apiKeys?.map((webhook) => {
                    return <TableRow key={webhook.clientId}>
                        <TableCell>{webhook.clientId}</TableCell>
                        <TableCell>{webhook.clientSecret}</TableCell>

                        <TableCell>
                            <RowDeleteButton action={() => {
                                setShowDeleteDialog(true);
                            }}/>
                            <ConfirmationDialog open={showDeleteDialog}
                                                title={t('api_key_page.delete_dialog.title')}
                                                description={t('api_key_page.delete_dialog.description')}
                                                confirmText={t('button.delete')} cancelText={t('button.cancel')}
                                                onConfirm={handleDelete}
                                                onCancel={() => setShowDeleteDialog(false)}/>
                        </TableCell>
                    </TableRow>
                }) || []}
                loading={props.loading}
                createAction={handleCreate}
                disableCreateButton={props.apiKeys ? props.apiKeys.length > 0 : false}
            />
        </React.Fragment>
    )
}
