import {Checkbox, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Asset} from "../../../hooks/assets/dto/Asset";
import {Table, TableCell, TableRow} from "../core/Table";
import {buildAssigneeName, getAssetName} from "../../../utils/AssetUtils";

interface SelectAssetTableProps {
    loading: boolean;
    assets: Asset[];
    multiselect?: boolean;
    disableSelect?: boolean;
    selectedRows: number[];
    setSelectedRows: (selectedRows: number[]) => void;
}

export default function SelectAssetTable(props: Readonly<SelectAssetTableProps>) {
    const { t } = useTranslation();

    const handleRowSelect = (assetId: number) => {
        if(props.multiselect) {
            if (props.selectedRows.includes(assetId)) {
                props.setSelectedRows(props.selectedRows.filter(selectedAsset => selectedAsset !== assetId));
            } else {
                props.setSelectedRows([...props.selectedRows, assetId]);
            }
        } else {
            props.setSelectedRows([assetId]);
        }
    };

    const handleSelectAllAssets = () => {
        if (props.assets.length === props.selectedRows.length) {
            props.setSelectedRows([]);
        } else {
            props.setSelectedRows(props.assets.map(asset => asset.id));
        }
    };

    const selectHeader = props.multiselect ? (
        <Tooltip title={t("select.all")}>
        <Checkbox
            id="select"
            disabled={props.disableSelect}
            checked={props.assets.length === props.selectedRows.length}
            onChange={() => handleSelectAllAssets()}
        />
    </Tooltip>) : <></>;

    const columns = [
        { id:'select', content: selectHeader },
        { id:'driver', content: t('asset_table.driver') },
        { id:'asset', content: t('asset_table.asset') },
        { id:'group', content: t('asset_table.group') },
        { id:'type', content: t('asset_table.type') }
    ];

    const rows = props.assets?.map((asset) => {
        return <TableRow key={asset.id}>
            <TableCell>
                <Checkbox
                    id={"select-asset-" + asset.id}
                    disabled={props.disableSelect}
                    checked={props.selectedRows.includes(asset.id)}
                    onChange={() => handleRowSelect(asset.id)}
                />
            </TableCell>
            <TableCell>{buildAssigneeName(asset, t) || "-"}</TableCell>
            <TableCell>{getAssetName(asset)}</TableCell>
            <TableCell>{asset.groupName || "-"}</TableCell>
            <TableCell>{t(`asset_types.${asset.type.toLowerCase()}`) || "-"}</TableCell>
        </TableRow>

    });


    return (
        <Table
            id="select-asset-table"
            label="select-asset-table"
            columns={columns}
            disableCreateButton={true}
            rows={rows}
            loading={props.loading}/>
    );
}
